import React from 'react';
import { Toggle, Table } from '@sumup/circuit-ui';
// import { formatDateTimeToShow } from 'services/DateTimeFormatService';

export const getReaderAvailabilityData = reader =>
  fetch(`/reader_availability_api/${reader}`);

export const toggleAvailability = id => {
  const options = {
    method: 'POST'
  };
  return fetch(`/reader_availability_api/toggle/${id}`, options);
};

const getFlagEmoji = countryCode => {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

const renderAvailabilityButton = (id, isPublished, toggleAvailabilityModal) => {
  if (isPublished === undefined || isPublished === null) {
    return '';
  }
  const label = isPublished ? 'Available' : 'Offline';

  return (
    <Toggle
      label={label}
      checkedLabel="Available"
      uncheckedLabel="Not Available"
      noMargin={true}
      checked={isPublished}
      key={id}
      onChange={() => {
        toggleAvailabilityModal(id);
      }}
    ></Toggle>
  );
};

export const mapAvailabilities = (row, toggleAvailabilityModal) => [
  {
    children: renderAvailabilityButton(
      row.id,
      row.status === 'AVAILABLE',
      toggleAvailabilityModal
    )
  },
  { children: `${getFlagEmoji(row.country)} ${row.country}` },
  { children: row.updatedAt }
];

export const mapCountriesAvailable = data => {
  const countryCodes = [];
  const availableCountries = data
    .filter(item => {
      console.log('Filtering item:', item);
      const isUnique = !countryCodes.includes(item.country);
      if (isUnique) {
        countryCodes.push(item.country);
      }
      console.log('Country codes:', countryCodes);
      return isUnique && item;
    })
    .map(item => {
      console.log('Mapping item:', item);
      return {
        label: item.country,
        value: item.country
      };
    })
    .sort((prevItem, nextItem) => prevItem.label.localeCompare(nextItem.label));
  return availableCountries;
};

export const shouldRenderTable = (
  data,
  callback,
  translate,
  toggleAvailabilityModal
) => {
  console.warn(data);
  return data ? (
    <Table
      noShadow
      headers={[
        {
          children: translate('readerAvailability.available')
        },
        {
          children: translate(
            'cardReadersConfiguration.configsTableHeaders.country'
          )
        },
        {
          children: translate(
            'cardReadersConfiguration.configsTableHeaders.created_at'
          )
        }
      ]}
      rows={data.map(row => mapAvailabilities(row, toggleAvailabilityModal))}
    />
  ) : (
    callback()
  );
};

export const filterByCountry = (countryCode, entries) =>
  entries.filter(item => item.country === countryCode);
